import axios from 'axios';
import { getToken, notify } from '../utils';

export let host = 'https://wapi.vndm.uz';

export let headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json; charset=utf-8',
};

export let axiosInstance = axios.create({
  baseURL: `${host}`,
  headers,
  timeout: 300000,
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = getToken();
    config.headers = {
      'user-token': token,
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export const http = async (config = null) => {
  try {
    const res = await axiosInstance(config);
    return res.data;
  } catch (err) {
    notify('error', err.response?.data?.message);
    if (err.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('persist:root');
      window.location.href = '/login';
    }
    throw err;
  }
};

export const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};
