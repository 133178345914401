import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Layout } from 'antd';
import {
  DesktopOutlined,
  UserOutlined,
  BranchesOutlined,
  DatabaseOutlined,
  FileProtectOutlined,
  SlackOutlined,
} from '@ant-design/icons';

import { ROUTE_URLS } from '../../routes';

import classes from './Sidebar.module.scss';

const fullMenu = {
  admin: [
    {
      id: 1,
      label: 'Asosiy sahifa',
      icon: <DesktopOutlined />,
      path: '/',
    },
    {
      id: 2,
      label: 'Kassa',
      icon: <SlackOutlined />,
      path: '/checkout',
    },
    {
      id: 3,
      label: 'Xodimlar',
      icon: <UserOutlined />,
      path: '/users',
    },
    {
      id: 4,
      label: 'Filiallar',
      icon: <BranchesOutlined />,
      path: '/branches',
    },
    {
      id: 5,
      label: 'Sklad',
      icon: <DatabaseOutlined />,
      path: '/products',
    },
    {
      id: 6,
      label: 'Hisobot yuborish',
      icon: <FileProtectOutlined />,
      path: '/reports/create',
    },
  ],
  user: [
    {
      id: 1,
      label: 'Asosiy sahifa',
      icon: <DesktopOutlined />,
      path: '/',
    },
    {
      id: 2,
      label: 'Kassa',
      icon: <SlackOutlined />,
      path: '/checkout',
    },
    {
      id: 4,
      label: 'Filiallar',
      icon: <BranchesOutlined />,
      path: '/branches',
    },
    {
      id: 6,
      label: 'Hisobot yuborish',
      icon: <FileProtectOutlined />,
      path: '/reports/create',
    },
  ],
};

export const Sidebar = ({ collapsed, onCollapse }) => {
  const { isAdmin } = useSelector(state => state.authReducer);
  const { pathname } = useLocation();
  const menu = useMemo(
    () => (isAdmin ? fullMenu.admin : fullMenu.user),
    [isAdmin],
  );

  const closeSidebarForMobile = () => {
    if (window.innerWidth < 992) {
      onCollapse();
    }
  };

  const getMenuItem = item => {
    return {
      key: item.id,
      icon: item.icon,
      label: (
        <Link to={item.path} onClick={closeSidebarForMobile}>
          {item.label}
        </Link>
      ),
    };
  };

  return (
    <Layout.Sider
      breakpoint='lg'
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth='0'
    >
      <h2 className={`${classes.sidebarTitle} ant-typography`}>
        <Link to={ROUTE_URLS.HOME}>W&M</Link>
      </h2>
      <Menu
        theme='dark'
        defaultSelectedKeys={[
          menu.find(el => el.path === pathname)?.id.toString(),
        ]}
        mode='inline'
        items={menu.map(item => getMenuItem(item))}
      />
    </Layout.Sider>
  );
};
