import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Skeleton } from 'antd';

import { ROUTE_URLS } from '../../routes';
import { useUpdateUser, useUser } from '../../hooks';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const User = () => {
  const { user, isAdmin } = useSelector(state => state.authReducer);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, mutateAsync: updateUser } = useUpdateUser(id);
  const { data: userInfo, isLoading: isUserDataLoading } = useUser(id);

  useEffect(() => {
    if (!isAdmin && user.id.toString() !== id) {
      navigate(ROUTE_URLS.HOME);
      return;
    }
  }, [id, isAdmin, user, navigate]);

  const onFinish = values => {
    updateUser({ id, ...values });
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Ma'lumotlarni yangilash</h1>
          {isUserDataLoading ? (
            <Skeleton active />
          ) : (
            <Form
              name='fields'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                login: userInfo?.login,
              }}
            >
              <Form.Item label='Login' name='login'>
                <Input placeholder={'Login'} name='login' />
              </Form.Item>
              <Form.Item label='Parol' name='password'>
                <Input placeholder={'Parol'} name='password' type='password' />
              </Form.Item>
              <Form.Item className={'text-center'}>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
