import { Component } from "react";

import classes from "./ErrorBoundary.module.scss";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    const {
      state: { hasError, errorMessage },
      props: { children },
    } = this;

    if (hasError) {
      return (
        <div className={classes.errorBoundary}>
          <p>Something went wrong</p>
          <code>{errorMessage}</code>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
