import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { formatNumber } from '../utils';

const formatReportData = (reportData, reportDates) => {
  const data = [];
  for (const key in reportData) {
    const value = reportData[key];
    value.forEach(item => {
      const alreadyExist = data.find(el => el.id === item.product_id);
      if (!alreadyExist) {
        data.push({
          id: item.product_id,
          productName: item.name,
          totalQuantity: +item.total_quantity,
          totalProfit: +item.total_profit,
        });
      } else {
        alreadyExist.totalQuantity += +item.total_quantity;
        alreadyExist.totalProfit += +item.total_profit;
      }
    });
  }

  // last column in table
  reportDates.forEach(date => {
    data.forEach(item => {
      const foundData = reportData[date]?.find(
        data => data.product_id === item.id,
      );
      item[date] = {
        quantity: foundData?.total_quantity || 0,
        profit: foundData?.total_profit || 0,
      };
    });
  });

  //  Last row columns
  const total = {};
  for (const key in reportData) {
    total[key] = reportData[key].reduce(
      (acc, item) => {
        return {
          quantity: acc.quantity + +item.total_quantity,
          profit: acc.profit + +item.total_profit,
        };
      },
      { quantity: 0, profit: 0 },
    );
  }

  // Last row in table
  data.push({
    id: 'total',
    productName: 'Jami',
    totalQuantity: data.reduce((acc, item) => acc + item.totalQuantity, 0),
    totalProfit: data.reduce((acc, item) => acc + item.totalProfit, 0),
    ...total,
  });

  const lineData = [];

  for (const [key, value] of Object.entries(total)) {
    lineData.push({ reportDate: key, profit: value.profit });
  }

  const barData = data
    .filter(el => el.productName !== 'Jami')
    .sort((a, b) => b.totalQuantity - a.totalQuantity)
    .map(el => {
      return { productName: el.productName, totalProfit: el.totalProfit };
    });

  const pieData = data
    .filter(el => el.productName !== 'Jami' && el.totalQuantity > 0)
    .map(el => {
      return { productName: el.productName, totalQuantity: el.totalQuantity };
    });

  return { data, lineData, barData, pieData };
};

export const useReportData = reportData => {
  const { isAdmin } = useSelector(state => state.authReducer);

  const filterColumn = useCallback(
    arr => {
      if (!isAdmin) {
        return arr.filter(a => a.title !== 'Foydasi');
      }
      return arr;
    },
    [isAdmin],
  );

  const reportDates = useMemo(() => {
    const array = [];
    for (const key in reportData) {
      array.push(key);
    }
    return array;
  }, [reportData]);

  const columns = useMemo(
    () => [
      {
        title: 'Mahsulot nomi',
        dataIndex: 'productName',
        width: 180,
      },
      ...reportDates?.map(date => ({
        title: date,
        onCell: (record, index) => ({
          style: { fontWeight: '900' },
        }),
        children: filterColumn([
          {
            title: 'Soni',
            dataIndex: [date, 'quantity'],
            render: (text, data) => `${formatNumber(text)}`,
            sorter: (a, b) => a[date]?.quantity - b[date]?.quantity,
          },
          {
            title: 'Foydasi',
            dataIndex: [date, 'profit'],
            render: (text, data) => `${formatNumber(text)}`,
            sorter: (a, b) => a[date]?.profit - b[date]?.profit,
          },
        ]),
      })),
      {
        title: 'Umumiy',
        children: filterColumn([
          {
            title: 'Soni',
            dataIndex: 'totalQuantity',
            onCell: (record, index) => ({
              style: { fontWeight: '900' },
            }),
            sorter: (a, b) => a.totalQuantity - b.totalQuantity,
          },
          {
            title: 'Foydasi',
            dataIndex: 'totalProfit',
            onCell: (record, index) => ({
              style: { fontWeight: '900' },
            }),
            render: (text, data) => `${formatNumber(text)}`,
            sorter: (a, b) => a.totalProfit - b.totalProfit,
          },
        ]),
      },
    ],
    [filterColumn, reportDates],
  );

  const dataSource = useMemo(() => {
    if (!reportData) return [];
    return formatReportData(reportData, reportDates);
  }, [reportData, reportDates]);

  return { columns, ...dataSource };
};
