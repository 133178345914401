import { Skeleton, Table } from 'antd';

export function CustomTable({
  isLoading,
  columns,
  dataSource,
  className = '',
}) {
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          tableLayout='fixed'
          bordered
          columns={columns}
          dataSource={dataSource}
          rowKey='id'
          scroll={{ x: 'max-content' }}
          pagination={{ pageSize: 50 }}
          className={className}
        />
      )}
    </>
  );
}
