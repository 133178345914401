import { http, methods } from './config';

export const getUsers = () => {
  const config = {
    method: methods.get,
    url: `/api/admin/users`,
  };
  return http(config);
};

export const getUser = id => {
  const config = {
    method: methods.get,
    url: `/api/users/${id}`,
  };
  return http(config);
};

export const createUser = data => {
  const config = {
    method: methods.post,
    url: `/api/admin/users`,
    data,
  };
  return http(config);
};

export const updateUser = ({ id, ...data }) => {
  const config = {
    method: methods.put,
    url: `/api/users/${id}`,
    data,
  };
  return http(config);
};

export const deleteUser = id => {
  const config = {
    method: methods.delete,
    url: `/api/admin/users/${id}`,
  };
  return http(config);
};
