import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGuest: true,
  isAdmin: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      state.isGuest = false;
      state.user = {
        id: payload.id,
        login: payload.login,
        token: payload[`user-token`],
      };
      state.isAdmin = payload.is_su;
    },
    logoutUser: state => {
      state.isGuest = true;
      state.user = null;
      state.isAdmin = false;
    },
  },
});

const authReducer = authSlice.reducer;
const authReducerActions = authSlice.actions;

export { authSlice, authReducer, authReducerActions };
