import { http, methods } from './config';

export const getProducts = () => {
  const config = {
    method: methods.get,
    url: `/api/products`,
  };
  return http(config);
};

export const getProduct = id => {
  const config = {
    method: methods.get,
    url: `/api/products/${id}`,
  };
  return http(config);
};

export const createProduct = data => {
  const config = {
    method: methods.post,
    url: `/api/admin/products`,
    data,
  };
  return http(config);
};

export const updateProduct = ({ id, ...data }) => {
  const config = {
    method: methods.put,
    url: `/api/admin/products/${id}`,
    data,
  };
  return http(config);
};

export const deleteProduct = id => {
  const config = {
    method: methods.delete,
    url: `/api/admin/products/${id}`,
  };
  return http(config);
};

export const addProducts = data => {
  const config = {
    method: methods.post,
    url: `/api/admin/products/add`,
    data,
  };
  return http(config);
};
