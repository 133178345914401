export const getToday = () => {
  return formatter(new Date());
};

export const get7DaysBefore = () => {
  return formatter(new Date(new Date().setDate(new Date().getDate() - 6)));
};

const formatter = date => {
  const today = date;
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
};