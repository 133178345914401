import { useQuery } from 'react-query';

import { getCheckout } from '../api';

export const useCheckout = params => {
  const { start_date, end_date, branch_id } = params;
  return useQuery(
    ['checkout', start_date, end_date, branch_id],
    async () => {
      const res = await getCheckout(params);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};
