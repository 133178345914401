import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  addProducts,
} from '../api';
import { ROUTE_URLS } from '../routes';
import { getNotifyMessage, notify } from '../utils';

export const useProducts = () => {
  return useQuery(
    'products',
    async () => {
      const res = await getProducts();
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useProduct = id => {
  return useQuery(
    ['product', id],
    async () => {
      const res = await getProduct(id);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateProduct = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createProduct, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'create', 'Mahsulot'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.PRODUCTS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'create', 'Mahsulot'));
    },
  });
};

export const useUpdateProduct = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(updateProduct, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'update', 'Mahsulot'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.PRODUCTS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'update', 'Mahsulot'));
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProduct, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'delete', 'Mahsulot'));
      queryClient.invalidateQueries();
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'delete', 'Mahsulot'));
    },
  });
};

export const useAddProducts = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addProducts, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'add', 'Mahsulot'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.PRODUCTS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'add', 'Mahsulot'));
    },
  });
};
