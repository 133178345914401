import React from "react";
import { Link } from "react-router-dom";

import { ROUTE_URLS } from "../../../routes";

import classes from "./NotFound.module.scss";
import '../../../styles/helper.scss'

export const NotFound = () => {
  return (
    <div className={classes.outerDiv}>
      <div className={'center-element'}>
        404
        <br />
        Sahifa topilmadi
        <br />
        <Link to={ROUTE_URLS.HOME} className={classes.link}>
          Bosh sahifa
        </Link>
      </div>
    </div>
  );
};
