import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import routes, { ROUTE_URLS } from "./routes";
import { Login } from "./pages";
import { CustomLayout } from "./components";
import { useSelector } from "react-redux";

export const App = () => {
  const { isGuest, isAdmin } = useSelector((state) => state.authReducer);

  const getComponentForRoute = (route) => {
    if (isGuest) {
      return <Navigate replace to={ROUTE_URLS.LOGIN} />;
    }
    if (route.restricted && !isAdmin) {
      return <Navigate replace to={ROUTE_URLS.HOME} />;
    }
    return (
      <CustomLayout>
        <route.component />
      </CustomLayout>
    );
  };

  return (
    <BrowserRouter basename="/">
      <React.Suspense>
        <Routes>
          <Route
            exact
            path={ROUTE_URLS.LOGIN}
            name="Login Page"
            element={
              isGuest ? <Login /> : <Navigate replace to={ROUTE_URLS.HOME} />
            }
          />
          {routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={getComponentForRoute(route)}
            />
          ))}
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};
