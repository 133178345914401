import { http, methods } from './config';

export const login = data => {
  const config = {
    method: methods.post,
    url: `/api/auth/login`,
    data,
  };
  return http(config);
};

export const logout = () => {
  const config = {
    method: methods.post,
    url: `/api/auth/logout`,
  };
  return http(config);
};
