import { useState, useMemo } from 'react';
import { Row, Col, Form, Input, Button, Select, Skeleton } from 'antd';

import { useProducts, useBranches, useCreateReport } from '../../hooks';
import {
  getToday,
  notify,
  removeObjectProperties,
  searchFn,
} from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const CreateReport = () => {
  const [searchText, setSearchText] = useState('');

  const { data: products, isLoading: isProductsLoading } = useProducts();
  const { data: branches, isLoading: isBranchesLoading } = useBranches();
  const { mutateAsync: createReport, isLoading } = useCreateReport();

  const defaultValues = useMemo(() => {
    const values = {
      date: getToday(),
    };
    products?.forEach(product => {
      values[product.id] = 0;
    });
    return values;
  }, [products]);

  const filteredProducts = useMemo(() => {
    return searchFn(products, searchText);
  }, [products, searchText]);

  const isValidReport = sales => {
    for (const key in sales) {
      if (+sales[key] < 0) {
        return false;
      }
    }

    return true;
  };

  const onFinish = values => {
    const {
      branchId: branch_id,
      date: report_date,
      ...sales
    } = removeObjectProperties(values, ['productName']);
    if (!isValidReport(sales)) {
      return notify(
        'error',
        "Sotilgan mahsulotlar soni 0 dan kichik bo'lmasligi kerak",
      );
    }

    const readyValues = {
      branch_id,
      report_date,
      product_ids: sales,
    };
    createReport(readyValues);
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Hisobot yuborish</h1>
          {isProductsLoading || isBranchesLoading ? (
            <Skeleton active />
          ) : (
            <Form
              name='report'
              layout='vertical'
              onFinish={onFinish}
              initialValues={defaultValues}
            >
              <Form.Item
                label='Filial'
                name='branchId'
                rules={[
                  {
                    required: true,
                    message: `Filial!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={'Filial'}
                  className='page-selection'
                >
                  {branches.map(branch => (
                    <Select.Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Sana'
                name='date'
                rules={[
                  {
                    required: true,
                    message: `Sana`,
                  },
                ]}
              >
                <Input placeholder={'Sana'} type='date' name='date' />
              </Form.Item>
              <Form.Item label={'Mahsulot qidirish'} name={'productName'}>
                <Input
                  placeholder={'Mahsulot nomi'}
                  type='text'
                  name={'productName'}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </Form.Item>
              {filteredProducts.map(product => (
                <Form.Item
                  key={product.id}
                  label={product.name}
                  name={product.id}
                >
                  <Input
                    placeholder={product.name}
                    type='number'
                    name={product.id}
                  />
                </Form.Item>
              ))}
              <Form.Item className={'text-center'}>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
