import { Row, Col, Form, Input, Button, Select, Skeleton } from 'antd';

import { useCreateBranch, useUsers } from '../../hooks';
import { removeObjectProperties } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const CreateBranch = () => {
  const { isLoading, mutateAsync: createBranch } = useCreateBranch();
  const { data: users, isLoading: usersLoading } = useUsers();

  const onFinish = values => {
    const readyValues = removeObjectProperties(values, ['productName']);
    createBranch(readyValues);
  };

  return (
    <>
      <>
        <Row>
          <Col md={12} lg={8} xs={24} className={'formHolder'}>
            <h1>Filial yaratish</h1>
            {usersLoading ? (
              <Skeleton active />
            ) : (
              <Form name='fields' layout='vertical' onFinish={onFinish}>
                <Form.Item
                  label='Nomi'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: `Nomi`,
                    },
                  ]}
                >
                  <Input placeholder={'Nomi'} name='name' />
                </Form.Item>
                <Form.Item
                  label='Biriktirilgan xodim'
                  name='user_ids'
                  rules={[
                    {
                      required: true,
                      message: `Xodim!`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={'Xodim'}
                    className='page-selection'
                    mode='multiple'
                  >
                    {users?.map(user => (
                      <Select.Option value={user.id} key={user.id}>
                        {user.login}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item className='text-center'>
                  <Button type='primary' htmlType='submit' loading={isLoading}>
                    Saqlash
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </>
    </>
  );
};
