import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Select, Skeleton } from 'antd';

import { useBranch, useUsers, useUpdateBranch } from '../../hooks';
import { removeObjectProperties } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const EditBranch = () => {
  const { id } = useParams();
  const { isLoading, mutateAsync: editBranch } = useUpdateBranch();
  const { data: branch, isLoading: isBranchLoading } = useBranch(id);
  const { data: users, isLoading: isUsersLoading } = useUsers();

  const onFinish = values => {
    const readyValues = removeObjectProperties(values, ['productName']);
    editBranch({ id, ...readyValues });
  };
  const defaultValues = useMemo(
    () => ({
      user_ids: branch?.users.map(user => user.id),
      name: branch?.name,
    }),
    [branch],
  );

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Filialni tahrirlash</h1>
          {isBranchLoading || isUsersLoading ? (
            <Skeleton active />
          ) : (
            <Form
              name='fields'
              layout='vertical'
              onFinish={onFinish}
              initialValues={defaultValues}
            >
              <Form.Item
                label='Nomi'
                name='name'
                rules={[
                  {
                    required: true,
                    message: `Nomi`,
                  },
                ]}
              >
                <Input placeholder={'Nomi'} name='name' />
              </Form.Item>
              <Form.Item
                label='Biriktirilgan xodim'
                name='user_ids'
                rules={[
                  {
                    required: true,
                    message: `Xodim!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={'Xodim'}
                  className='page-selection'
                  mode='multiple'
                >
                  {users?.map(user => (
                    <Select.Option value={user.id} key={user.id}>
                      {user.login}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className={'text-center'}>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
