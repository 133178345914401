import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Skeleton } from 'antd';

import { useProduct, useBranches, useUpdateProduct } from '../../hooks';
import { removeObjectProperties, searchFn } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const EditProduct = () => {
  const { id } = useParams();

  const { data: product, isLoading: isProductDataLoading } = useProduct(id);
  const { data: branches, isLoading: isLoadingBranches } = useBranches();
  const { mutateAsync: editProduct, isLoading } = useUpdateProduct(id);
  const [branchSearchValue, setBranchSearchValue] = useState('');

  const filteredBranches = useMemo(() => {
    if (!Array.isArray(branches)) return [];
    return searchFn(branches, branchSearchValue);
  }, [branchSearchValue, branches]);

  const defaultValues = useMemo(() => {
    const values = {};
    product?.branches.forEach(branch => {
      values[branch.id] = branch.sell_price;
    });
    return values;
  }, [product]);

  const createSellPricesArray = values => {
    const branchSellPrices = {};
    branches.forEach(branch => {
      branchSellPrices[branch.id] = values[branch.id];
    });
    return branchSellPrices;
  };

  const onFinish = values => {
    const readyValues = {
      status: 1,
      branch_ids: createSellPricesArray(values),
      ...removeObjectProperties(values, [
        'branchSearchValue',
        ...branches.map(branch => branch.id),
      ]),
    };
    editProduct({ ...readyValues, id });
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Mahsulotni yangilash</h1>
          {isProductDataLoading || isLoadingBranches ? (
            <Skeleton active />
          ) : (
            <Form
              name='createProducts'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                ...product,
                ...defaultValues,
              }}
            >
              <Form.Item
                label='Mahsulot nomi'
                name='name'
                rules={[
                  {
                    required: true,
                    message: `Nomi`,
                  },
                ]}
              >
                <Input placeholder={'Mahsulot nomi'} name='name' />
              </Form.Item>
              <Form.Item
                label='Sotib olingan narxi'
                name='buy_price'
                rules={[
                  {
                    required: true,
                    message: `Narx`,
                  },
                ]}
              >
                <Input
                  placeholder={'Sotib olingan narxi'}
                  type={'number'}
                  name='buy_price'
                />
              </Form.Item>
              <Form.Item
                label='Mahsulot miqdori'
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: `Miqdor`,
                  },
                ]}
              >
                <Input
                  placeholder={'Mahsulot miqdori'}
                  type={'number'}
                  name='quantity'
                />
              </Form.Item>
              <Form.Item label='Filialni qidirish' name='branchSearch'>
                <Input
                  placeholder={'Filialni qidirish'}
                  name='branchSearch'
                  value={branchSearchValue}
                  onChange={e => setBranchSearchValue(e.target.value)}
                />
              </Form.Item>
              {filteredBranches.map(branch => (
                <Form.Item
                  key={branch.id}
                  label={`${branch.name} Sotuv narxi`}
                  name={`${branch.id}`}
                  rules={[
                    {
                      required: true,
                      message: `Narx`,
                    },
                  ]}
                >
                  <Input
                    placeholder={'Sotuv narxi'}
                    name={`${branch.id}`}
                    type={'number'}
                  />
                </Form.Item>
              ))}
              <Form.Item className='text-center'>
                <Button type='primary' htmlType={'submit'} loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
