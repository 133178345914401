import { http, methods } from './config';

export const getReports = params => {
  const config = {
    method: methods.get,
    url: `/api/reports`,
    params,
  };
  return http(config);
};

export const getReport = id => {
  const config = {
    method: methods.get,
    url: `/api/admin/reports/${id}`,
  };
  return http(config);
};

export const createReport = data => {
  const config = {
    method: methods.post,
    url: `/api/reports`,
    data,
  };
  return http(config);
};
