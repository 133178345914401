import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Select, Form, Input, Skeleton } from 'antd';

import { useBranches, useReportData, useReports } from '../../hooks';
import { CustomTable } from '../../components';
import { get7DaysBefore, getToday } from '../../utils';
import { LineCh, BarCh, PieCh } from '../../components/Charts';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';
import {useSelector} from "react-redux";

export const Reports = () => {
  const { id } = useParams();
  const {isAdmin} = useSelector(state => state.authReducer)
  const [selectedBranch, setSelectedBranch] = useState(id ? +id : 0);
  const [startDate, setStartDate] = useState(get7DaysBefore());
  const [endDate, setEndDate] = useState(getToday());

  const params = useMemo(() => {
    return {
      branch_id: selectedBranch,
      start_date: startDate,
      end_date: endDate,
    };
  }, [selectedBranch, startDate, endDate]);

  const { data: branches, isLoading: isLoadingBranches } = useBranches();
  const { data, isLoading: isLoadingReports } = useReports(params);

  const {
    columns,
    data: dataSource,
    lineData,
    barData,
    pieData,
  } = useReportData(data);

  const selectedBranchName = useMemo(
    () =>
      branches?.find(branch => branch.id === selectedBranch)?.name ??
      'Barcha filiallar',
    [branches, selectedBranch],
  );

  return (
    <>
      <Form name='reportForm' initialValues={{ startDate, endDate }}>
        <Row className={'center mb-2'} gutter={16}>
          <Col xs={24} sm={12} md={8} lg={6} className={'tableTitle'}>
            <h1>{selectedBranchName} hisoboti</h1>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            {isLoadingBranches ? (
              <Skeleton active />
            ) : (
              <Select
                showSearch
                placeholder={'Filial'}
                name='branch'
                className='page-selection'
                onChange={value => setSelectedBranch(value)}
                value={selectedBranch}
                rootClassName={'width-100 mb-1'}
              >
                <Select.Option value={0}>{'Barcha filiallar'}</Select.Option>
                {branches.map(branch => (
                  <Select.Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label='Start date'
              name='startDate'
              className={'mb-0 mb-1'}
            >
              <Input
                placeholder={'Sana'}
                type='date'
                name='startDate'
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item label='End Date' name='endDate' className={'mb-0 mb-1'}>
              <Input
                placeholder={'Sana'}
                type='date'
                name='endDate'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomTable
        isLoading={isLoadingReports || !data}
        columns={columns}
        dataSource={dataSource}
        className="reportTable"
      />
      {isAdmin && <LineCh data={lineData}/>}
      {isAdmin && <BarCh data={barData}/>}
      <PieCh data={pieData}/>
      </>
  );
};
