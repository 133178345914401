import React from 'react';
import { Modal, Row, Col } from 'antd';

export const DeleteConfirmModal = ({ onConfirm, onCancel, open }) => {
  return (
    <>
      <Modal
        title={"O'chirish"}
        centered
        width={350}
        open={open}
        onOk={onConfirm}
        okType='danger'
        okText={"O'chirish"}
        cancelText={'Bekor qilish'}
        onCancel={onCancel}
      >
        <Row align='middle' gutter={[8]}>
          <Col>{"Haqiqatan ham buni o'chirishni xohlaysizmi?"}</Col>
        </Row>
      </Modal>
    </>
  );
};
