import { http, methods } from './config';

export const getBranches = () => {
  const config = {
    method: methods.get,
    url: `/api/branches`,
  };
  return http(config);
};

export const getBranch = id => {
  const config = {
    method: methods.get,
    url: `/api/branches/${id}`,
  };
  return http(config);
};

export const createBranch = data => {
  const config = {
    method: methods.post,
    url: `/api/admin/branches`,
    data,
  };
  return http(config);
};

export const updateBranch = ({ id, ...data }) => {
  const config = {
    method: methods.put,
    url: `/api/admin/branches/${id}`,
    data,
  };
  return http(config);
};

export const deleteBranch = id => {
  const config = {
    method: methods.delete,
    url: `/api/admin/branches/${id}`,
  };
  return http(config);
};

export const updateBranchPrices = ({ id, ...data }) => {
  const config = {
    method: methods.put,
    url: `/api/branches/${id}/change-prices`,
    data,
  };
  return http(config);
};
