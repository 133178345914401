import { useMemo, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form, Input } from 'antd';

import {CustomTable} from "../../components";
import { createLink, formatNumber, searchFn } from '../../utils';
import { useBranch } from '../../hooks';
import { ROUTE_URLS } from '../../routes';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const BranchPrices = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        title: 'Mahsulot nomi',
        dataIndex: 'name',
      },
      {
        title: 'Mahsulot narxi',
        dataIndex: 'sell_price',
        render: sell_price => `${formatNumber(sell_price)} so'm`,
        sorter: (a, b) => a.sell_price - b.sell_price,
      },
    ],
    [],
  );
  const { data: branch, isLoading, isError } = useBranch(id);

  useEffect(() => {
    if (isError) {
      navigate(ROUTE_URLS.BRANCHES);
    }
  }, [isError, navigate]);

  const [searchText, setSearchText] = useState('');

  const filteredProducts = useMemo(
    () => searchFn(branch?.products, searchText),
    [branch, searchText],
  );

  return (
    <>
      <Form>
        <Row className={'center'} gutter={16}>
          <Col xs={24} sm={12} md={12} lg={6} className={'tableTitle'}>
            <h1>{branch?.name}dagi narxlar</h1>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} className={'tableTitle'}>
            <Form.Item
              label='Mahsulot nomi'
              name='name'
              className={'width-100 mb-0'}
            >
              <Input
                placeholder='Mahsulot nomini kiriting'
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                rootClassName={'width-100'}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            md={5}
            sm={7}
            lg={10}
            className={'flex-end mb-1 text-center'}
          >
            <Link to={createLink(ROUTE_URLS.EDIT_BRANCH_PRICES, id)}>
              <Button type='primary' className={'display-block'}>
                Narxlarni o'zgartirish
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
      <CustomTable dataSource={filteredProducts} columns={columns} isLoading={isLoading} />
    </>
  );
};
