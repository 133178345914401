export const getNotifyMessage = (type, action, text) => {
  switch (type) {
    case 'success':
      const successVerb =
        action === 'create'
          ? 'muvaffaqiyatli yaratildi'
          : action === 'update'
          ? 'muvaffaqiyatli yangilandi'
          : action === 'delete'
          ? "muvaffaqiyatli o'chirildi"
          : action === 'add'
          ? 'muvaffaqiyatli qo`shildi'
          : action;
      return `${text} ${successVerb}`;
    case 'error':
      const errorVerb =
        action === 'create'
          ? 'yaratishda xatolik'
          : action === 'update'
          ? 'yangilashda xatolik'
          : action === 'delete'
          ? "o'chirishda xatolik"
          : action === 'add'
          ? 'qo`shishda xatolik'
          : action;
      return `${text} ${errorVerb}`;
    default:
      break;
  }
};
