import React from "react";
import { Layout } from "antd";

export const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      ©2023 Created by{" "}
      <a
        href="https://www.linkedin.com/in/bahodir-abdullayev-8a7373200/"
        target={"_blank"}
        rel="noreferrer"
      >
        Abdullayev Bahodir
      </a>
    </Layout.Footer>
  );
};
