import { useMutation, useQueryClient } from 'react-query';

import { login, logout } from '../api';
import { notify } from '../utils';

export const useLogin = () => {
  return useMutation(login, {
    onSuccess: res => {
      const data = res.data;
      localStorage.setItem('token', data[`user-token`]);
      notify('success', 'Login Success');
    },
    onError: () => {
      notify('error', 'Login Error');
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(logout, {
    onSuccess: () => {
      localStorage.removeItem('token');
      queryClient.invalidateQueries();
      notify('success', 'Logout Success');
    },
    onError: () => {
      notify('error', 'Logout Error');
    },
  });
};
