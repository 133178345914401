import { useState, useMemo } from 'react';
import { Row, Col, Form, Input, Button, Skeleton } from 'antd';

import { useProducts, useAddProducts } from '../../hooks';
import { notify, removeObjectProperties, searchFn } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const AddProduct = () => {
  const [searchText, setSearchText] = useState('');
  const { data: products, isLoading: isDataLoading } = useProducts();
  const { mutateAsync: addProducts, isLoading } = useAddProducts();

  const defaultValues = useMemo(() => {
    const values = {};
    products?.forEach(product => {
      values[product.id] = product.sell_price ?? 0;
    });
    return values;
  }, [products]);

  const filteredProducts = useMemo(() => {
    if (searchText) {
      return searchFn(products, searchText);
    }
    return products;
  }, [products, searchText]);

  const isValid = data => {
    for (const key in data) {
      if (+data[key] < 0) {
        return false;
      }
    }
    return true;
  };

  const onFinish = values => {
    const readyValues = {
      data: {
        ...removeObjectProperties(values, ['productName']),
      },
    };
    if (!isValid(readyValues.data)) {
      return notify('error', "Mahsulot soni 0 dan kichik bo'lmasligi kerak");
    }
    addProducts(readyValues);
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Mahsulot qo'shish</h1>
          {isDataLoading ? (
            <Skeleton active />
          ) : (
            <Form
              name='fields'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                ...defaultValues,
              }}
            >
              <Form.Item label={'Mahsulot qidirish'} name={'productName'}>
                <Input
                  placeholder={'Mahsulot nomi'}
                  type='text'
                  name={'productName'}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </Form.Item>
              {filteredProducts.map(product => (
                <Form.Item
                  label={product.name}
                  name={product.id}
                  key={product.id}
                  rules={[
                    {
                      required: true,
                      message: `Narx`,
                    },
                  ]}
                >
                  <Input placeholder={'Narx'} type='number' name={product.id} />
                </Form.Item>
              ))}
              <Form.Item className={'text-center'}>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
