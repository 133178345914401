import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Layout } from 'antd';
import {
  LogoutOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import { authReducerActions } from '../../store/reducers';
import { useLogout } from '../../hooks';
import { ROUTE_URLS } from '../../routes';
import { createLink } from '../../utils';

import classes from './Header.module.scss';
import '../../styles/pageStyles.scss';

export const Header = ({ collapsed, onCollapse }) => {
  const { user } = useSelector(state => state.authReducer);
  const { mutateAsync: logoutUserRQ } = useLogout();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(authReducerActions.logoutUser());
    logoutUserRQ();
  };

  return (
    <Layout.Header className={classes.header}>
      <Button className={classes.trigger} type={'primary'} onClick={onCollapse}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <div className={classes['btn-wrapper']}>
        <Link to={createLink(ROUTE_URLS.EDIT_USER, user.id)}>
          <Button type='primary'>
            <EditOutlined />
          </Button>
        </Link>
        <Button type='primary' danger onClick={logout}>
          <LogoutOutlined />
        </Button>
      </div>
    </Layout.Header>
  );
};
