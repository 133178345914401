import React, {useState} from "react";
import { Layout } from "antd";

import { Header, Sidebar, Footer } from "../index";

import classes from "./CustomLayout.module.scss";

const { Content } = Layout;

export const CustomLayout = ({ route, children }) => {
  const [collapsed, setCollapsed] = useState(false)

    const onCollapse = () => {
      setCollapsed(!collapsed)
    }

  return (
    <Layout className={classes.layout}>
      <Sidebar collapsed={collapsed} onCollapse={onCollapse} />
      <Layout className="site-layout">
        <Header collapsed={collapsed} onCollapse={onCollapse} />
        <Content className={classes.content}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
