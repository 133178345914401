import {useMemo} from "react";
import {Cell, PieChart, Pie, Tooltip, Legend, ResponsiveContainer} from "recharts";

import {colorGenerator} from "../../utils";

import {ChartContainer} from "./ChartContainer";

export function PieCh({data}) {
    const colors = useMemo(() => colorGenerator(data), [data])

    return (
        <>
            {
                data?.length > 0 && <ChartContainer title={'Sotuvdagi mahsulotlarning ulushi'}>
                    <ResponsiveContainer width={'100%'} height={250}>
                        <PieChart>
                            <Pie
                                data={data}
                                dataKey="totalQuantity"
                                nameKey="productName"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                label
                            >
                                {
                                    data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length] + ''}/>
                                    ))
                                }
                            </Pie>
                            <Tooltip/>
                            <Legend/>
                        </PieChart>
                    </ResponsiveContainer>
                </ChartContainer>
            }
        </>

    )
}