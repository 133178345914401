import { useMemo, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { useBranches, useCreateProduct } from '../../hooks';
import { removeObjectProperties, searchFn } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const CreateProduct = () => {
  const [branchSearchValue, setBranchSearchValue] = useState('');

  const { data: branches } = useBranches();
  const { isLoading, mutateAsync: createProduct } = useCreateProduct();

  const filteredBranches = useMemo(
    () => searchFn(branches, branchSearchValue),
    [branches, branchSearchValue],
  );

  const createSellPricesArray = values => {
    const branchSellPrices = {};
    branches.forEach(branch => {
      branchSellPrices[branch.id] = values[branch.id];
    });
    return branchSellPrices;
  };

  const onFinish = values => {
    const readyValues = {
      status: 1,
      branch_ids: createSellPricesArray(values),
      ...removeObjectProperties(values, [
        'branchSearchValue',
        ...branches.map(branch => branch.id),
      ]),
    };

    createProduct(readyValues);
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Mahsulot yaratish</h1>
          <Form
            name='createProducts'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              quantity: 0,
            }}
          >
            <Form.Item
              label='Mahsulot nomi'
              name='name'
              rules={[
                {
                  required: true,
                  message: `Nomi`,
                },
              ]}
            >
              <Input placeholder={'Mahsulot nomi'} name='name' />
            </Form.Item>
            <Form.Item
              label='Sotib olingan narxi'
              name='buy_price'
              rules={[
                {
                  required: true,
                  message: `Narx`,
                },
              ]}
            >
              <Input
                placeholder={'Sotib olingan narxi'}
                type={'number'}
                name='buy_price'
              />
            </Form.Item>
            <Form.Item
              label='Mahsulot miqdori'
              name='quantity'
              rules={[
                {
                  required: true,
                  message: `Miqdor`,
                },
              ]}
            >
              <Input
                placeholder={'Mahsulot miqdori'}
                type={'number'}
                name='quantity'
              />
            </Form.Item>
            <Form.Item label='Filialni qidirish' name='branchSearchValue'>
              <Input
                placeholder={'Filialni qidirish'}
                name='branchSearchValue'
                value={branchSearchValue}
                onChange={e => setBranchSearchValue(e.target.value)}
              />
            </Form.Item>
            {filteredBranches?.map(branch => (
              <Form.Item
                key={branch.id}
                label={`${branch.name} Sotuv narxi`}
                name={branch.id}
                rules={[
                  {
                    required: true,
                    message: `Sotuv narxi`,
                  },
                ]}
              >
                <Input
                  placeholder={'Sotuv narxi'}
                  name={branch.id}
                  type={'number'}
                />
              </Form.Item>
            ))}
            <Form.Item className='text-center'>
              <Button type='primary' htmlType={'submit'} loading={isLoading}>
                Saqlash
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
