import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form, Input } from 'antd';

import { ROUTE_URLS } from '../../routes';
import { createLink, formatNumber, searchFn } from '../../utils';
import { useProducts, useBranches, useDeleteProduct } from '../../hooks';
import {CustomTable, DeleteConfirmModal} from '../../components';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const Products = () => {
  const { data: products, isLoading } = useProducts();
  const { data: branches, isLoading: isLoadingBranches } = useBranches();
  const { mutateAsync: deleteProduct } = useDeleteProduct();

  const findBranchPriceForProduct = useCallback((branchId, branchPrices) => {
    return branchPrices.find(el => el.id === branchId)?.sell_price || 0;
  }, []);

  const generatedColumns = useMemo(() => {
    if (!Array.isArray(branches)) return [];
    return branches?.map(el => {
      return {
        title: el.name,
        dataIndex: 'id',
        render: (text, product) =>
          `${formatNumber(findBranchPriceForProduct(el.id, product.branches))}`,
      };
    });
  }, [branches, findBranchPriceForProduct]);
  const columns = useMemo(
    () => [
      {
        title: 'Mahsulot nomi',
        dataIndex: 'name',
        render: (text, product) => (
          <Link to={createLink(ROUTE_URLS.EDIT_PRODUCT, product.id)}>
            {product.name}
          </Link>
        ),
      },
      {
        title: 'Qolgan miqdori',
        dataIndex: 'quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        width: 160,
      },
      {
        title: 'Sotib olingan narxi',
        dataIndex: 'buy_price',
        render: price => `${formatNumber(price)}`,
        sorter: (a, b) => a.buy_price - b.buy_price,
        width: 200,
      },
      ...generatedColumns,
      {
        title: 'Harakat',
        dataIndex: 'id',
        render: (text, product) => (
          <Button
            type='primary'
            danger
            onClick={() => {
              setSelectedProductId(product.id);
              setShowDeleteModal(true);
            }}
          >
            O'chirish
          </Button>
        ),
      },
    ],
    [generatedColumns],
  );

  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const filteredProducts = useMemo(() => {
    return searchFn(products, searchText);
  }, [products, searchText]);

  const confirmHandler = () => {
    deleteProduct(selectedProductId);
    resetState();
  };

  const resetState = () => {
    setShowDeleteModal(false);
    setSelectedProductId(null);
  };

  const cancelHandler = () => {
    resetState();
  };

  return (
    <>
      <DeleteConfirmModal
        open={showDeleteModal}
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
      />
      <Form>
        <Row className={'center mb-1'} gutter={16}>
          <Col xs={24} sm={8} md={12} lg={6}>
            <h1 className={'tableTitle'}>Mahsulotlar</h1>
          </Col>
          <Col className={'mb-1'} xs={24} sm={16} md={12} lg={8}>
            <Form.Item
              label='Mahsulot nomi'
              name='name'
              className={'width-100 mb-0'}
            >
              <Input
                placeholder='Mahsulot nomini kiriting'
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                rootClassName={'width-100'}
              />
            </Form.Item>
          </Col>
          <Col className={'width-100'} md={24} lg={10} gutter={16}>
            <div className={'flex-end mt-1 gap-1'}>
              <Link to={ROUTE_URLS.CREATE_PRODUCT}>
                <Button type='primary'>Yangi mahsulot yaratish</Button>
              </Link>
              <Link to={ROUTE_URLS.ADD_PRODUCT}>
                <Button type='default'>Mahsulot qo'shish</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      <CustomTable dataSource={filteredProducts} columns={columns} isLoading={isLoading || isLoadingBranches} />
    </>
  );
};
