import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getUsers, getUser, createUser, updateUser, deleteUser } from '../api';
import { ROUTE_URLS } from '../routes';
import { getNotifyMessage, notify } from '../utils';

export const useUsers = () => {
  return useQuery(
    'users',
    async () => {
      const res = await getUsers();
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useUser = id => {
  return useQuery(
    ['user', id],
    async () => {
      const res = await getUser(id);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createUser, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'create', 'User'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.USERS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'create', 'User'));
    },
  });
};

export const useUpdateUser = params => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'update', 'User'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.USERS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'update', 'User'));
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUser, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'delete', 'User'));
      queryClient.invalidateQueries();
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'delete', 'User'));
    },
  });
};
