import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  deleteBranch,
  updateBranchPrices,
} from '../api';
import { ROUTE_URLS } from '../routes';
import { createLink, getNotifyMessage, notify } from '../utils';

export const useBranches = () => {
  return useQuery(
    'branches',
    async () => {
      const res = await getBranches();
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useBranch = id => {
  return useQuery(
    ['branch', id],
    async () => {
      const res = await getBranch(id);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateBranch = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createBranch, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'create', 'Filial'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.BRANCHES);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'create', 'Filial'));
    },
  });
};

export const useUpdateBranch = params => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(updateBranch, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'update', 'Filial'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.BRANCHES);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'update', 'Filial'));
    },
  });
};

export const useDeleteBranch = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteBranch, {
    onSuccess: res => {
      notify('success', getNotifyMessage('success', 'delete', 'Filial'));
      queryClient.invalidateQueries();
    },
    onError: res => {
      notify('error', getNotifyMessage('error', 'delete', 'Filial'));
    },
  });
};

export const useUpdateBranchPrices = id => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(updateBranchPrices, {
    onSuccess: res => {
      notify('success', getNotifyMessage('success', 'update', 'Narxlar'));
      queryClient.invalidateQueries();
      navigate(createLink(ROUTE_URLS.BRANCH_PRICES, id));
    },
    onError: res => {
      notify('error', getNotifyMessage('error', 'update', 'Narxlar'));
    },
  });
};
