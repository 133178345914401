import { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Space, } from 'antd';

import { ROUTE_URLS } from '../../routes';
import { createLink } from '../../utils';
import {CustomTable, DeleteConfirmModal} from '../../components';
import { useDeleteUser, useBranches, useUsers } from '../../hooks';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const Users = () => {
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { data: branches, isLoading: isBranchesLoading } = useBranches();
  const { data: users, isLoading: isUsersLoading } = useUsers();

  const findConnectedBranches = useCallback(
    user => {
      return branches?.filter(branch => {
        return branch.users.find(conUser => {
          return conUser.id.toString() === user.id.toString();
        });
      });
    },
    [branches],
  );
  const columns = useMemo(
    () => [
      {
        title: 'Xodim nomi',
        dataIndex: 'login',
        render: (text, user) => (
          <Link to={createLink(ROUTE_URLS.EDIT_USER, user.id)}>
            {user.login}
          </Link>
        ),
      },
      {
        title: 'Biriktirilgan filiallar',
        dataIndex: 'id',
        render: (text, user) => (
          <>
            {findConnectedBranches(user).map(branch => (
              <div key={branch.id}>
                <Link to={createLink(ROUTE_URLS.EDIT_BRANCH, branch.id)}>
                  {branch.name}
                </Link>
              </div>
            ))}
          </>
        ),
      },
      {
        title: 'Harakat',
        dataIndex: 'id',
        render: (text, user) => (
          <Space size='middle'>
            <Button
              type='primary'
              danger
              onClick={() => {
                setSelectedUserId(user.id);
                setShowDeleteModal(true);
              }}
            >
              O'chirish
            </Button>
          </Space>
        ),
      },
    ],
    [findConnectedBranches],
  );

  const dataSource = useMemo(() => {
    return users?.filter(user => !user.isSU);
  }, [users]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const confirmHandler = () => {
    deleteUser(selectedUserId);
    resetState();
  };

  const resetState = () => {
    setShowDeleteModal(false);
    setSelectedUserId(null);
  };

  const cancelHandler = () => {
    resetState();
  };

  return (
    <>
      <DeleteConfirmModal
        open={showDeleteModal}
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
      />
      <Row>
        <Col span={24} className={'tableTitle'}>
          <h1>Xodimlar</h1>
          <Link to={ROUTE_URLS.CREATE_USER}>
            <Button type='primary'>Xodim qo'shish</Button>
          </Link>
        </Col>
      </Row>
      <CustomTable dataSource={dataSource} columns={columns} isLoading={isBranchesLoading || isUsersLoading} />
    </>
  );
};
