import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Skeleton } from 'antd';

import { useBranch, useUpdateBranchPrices } from '../../hooks';
import { removeObjectProperties, searchFn } from '../../utils';
import { ROUTE_URLS } from '../../routes';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const EditBranchPrices = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: branch, isLoading: isDataLoading, isError } = useBranch(id);
  const { mutateAsync: editBranchPrices, isLoading } =
    useUpdateBranchPrices(id);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (isError) {
      navigate(ROUTE_URLS.BRANCHES);
    }
  }, [isError, navigate]);

  const defaultValues = useMemo(() => {
    const values = {};
    branch?.products?.forEach(product => {
      values[product.id] = product.sell_price ?? 0;
    });
    return values;
  }, [branch]);

  const filteredProducts = useMemo(() => {
    if (searchText) {
      return searchFn(branch?.products, searchText);
    }
    return branch?.products;
  }, [branch, searchText]);

  const onFinish = values => {
    const readyValues = {
      id,
      product_ids: { ...removeObjectProperties(values, ['productName']) },
    };
    editBranchPrices(readyValues);
  };

  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>{branch?.name}dagi narxlarni o'zgartirish</h1>
          {isDataLoading ? (
            <Skeleton active />
          ) : (
            <Form
              name='fields'
              layout='vertical'
              onFinish={onFinish}
              initialValues={{
                ...defaultValues,
              }}
            >
              <Form.Item label={'Mahsulot qidirish'} name={'productName'}>
                <Input
                  placeholder={'Mahsulot nomi'}
                  type='text'
                  name={'productName'}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </Form.Item>
              {filteredProducts.map(product => (
                <Form.Item
                  label={product.name}
                  name={product.id}
                  key={product.id}
                  rules={[
                    {
                      required: true,
                      message: `Narx`,
                    },
                  ]}
                >
                  <Input placeholder={'Narx'} type='number' name={product.id} />
                </Form.Item>
              ))}
              <Form.Item className={'text-center'}>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Saqlash
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
