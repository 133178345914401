import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Button} from 'antd';

import { ROUTE_URLS } from '../../routes';
import { createLink } from '../../utils';
import { useBranches, useDeleteBranch } from '../../hooks';
import {CustomTable, DeleteConfirmModal} from '../../components';

import '../../styles/pageStyles.scss';

export const Branches = () => {
  const { isAdmin } = useSelector(state => state.authReducer);
  const { data: branches, isLoading } = useBranches();
  const { mutateAsync: deleteBranch } = useDeleteBranch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  const getComponent = useCallback(
    (route, data) => {
      if (isAdmin) {
        return (
          <Link to={createLink(route, data.id)}>{data.login || data.name}</Link>
        );
      }
      return data.login || data.name;
    },
    [isAdmin],
  );
  const actionsColumn = useCallback(() => {
    if (isAdmin) {
      return [
        {
          title: 'Harakat',
          dataIndex: 'id',
          render: (text, branch) => (
            <Button
              type='primary'
              danger
              onClick={() => {
                setSelectedBranchId(branch.id);
                setShowDeleteModal(true);
              }}
            >
              O'chirish
            </Button>
          ),
        },
      ];
    }
    return [];
  }, [isAdmin]);

  const columns = useMemo(
    () => [
      {
        title: 'Filial nomi',
        dataIndex: 'name',
        render: (text, branch) => getComponent(ROUTE_URLS.EDIT_BRANCH, branch),
      },
      {
        title: 'Biriktirilgan shaxslar',
        dataIndex: 'id',
        render: (text, branch) => {
          return (
            <>
              {branch.users.map(user => (
                <div key={user.id}>
                  {getComponent(ROUTE_URLS.EDIT_USER, user)}
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: 'Narxlar',
        dataIndex: 'id',
        render: id => (
          <Link to={createLink(ROUTE_URLS.BRANCH_PRICES, id)}>
            Filialdagi narxlar
          </Link>
        ),
      },
      {
        title: 'Hisobotlar',
        dataIndex: 'id',
        render: id => (
          <Link to={createLink(ROUTE_URLS.BRANCH_REPORTS, id)}>
            Filial hisobotlari
          </Link>
        ),
      },
      ...actionsColumn(),
    ],
    [getComponent, actionsColumn],
  );

  const confirmHandler = async () => {
    resetState();
    await deleteBranch(selectedBranchId);
  };

  const resetState = () => {
    setShowDeleteModal(false);
    setSelectedBranchId(null);
  };

  const cancelHandler = () => {
    resetState();
  };

  return (
    <>
      <DeleteConfirmModal
        open={showDeleteModal}
        onConfirm={confirmHandler}
        onCancel={cancelHandler}
      />
      <Row>
        <Col span={24} className={'tableTitle'}>
          <h1>Filiallar</h1>
          {isAdmin && (
              <Link to={ROUTE_URLS.CREATE_BRANCH}>
                <Button type='primary'>Filial qo'shish</Button>
              </Link>
          )}
        </Col>
      </Row>
      <CustomTable dataSource={branches} columns={columns} isLoading={isLoading}/>
    </>
  );
};
