import {CartesianGrid, AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";

import {ChartContainer} from "./ChartContainer";

export function LineCh({data}) {
    return (
        <>
            {
                data?.length > 0 && <ChartContainer title={'Kunlik daromad o\'zgarishi'}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <AreaChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <XAxis dataKey="reportDate"/>
                            <YAxis/>
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                            <Area type="monotone" dataKey="profit" stroke="#8884d8" fillOpacity={0.1} strokeWidth={2} />
                            <Tooltip/>
                        </AreaChart>
                    </ResponsiveContainer>
                </ChartContainer>
            }
        </>
    )
}