import { useDispatch } from 'react-redux';
import { Form, Input, Button } from 'antd';

import { authReducerActions } from '../../../store/reducers';
import { useLogin } from '../../../hooks';

import classes from './Login.module.scss';
import '../../../styles/helper.scss'

export const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, mutateAsync: login } = useLogin();

  const onFinish = async values => {
    const { data } = await login(values);
    dispatch(authReducerActions.loginUser(data));
  };

  return (
    <div className={classes.loginPage}>
      <div className={`${classes.formHolder} center-element`}>
        <h2>Login</h2>
        <Form name='login' layout='vertical' onFinish={onFinish}>
          <Form.Item label='Login' name='login'>
            <Input placeholder={'Login'} name='login' />
          </Form.Item>
          <Form.Item label='Parol' name='password'>
            <Input placeholder={'Parol'} name='password' type={'password'} />
          </Form.Item>
          <Form.Item className={'text-center'}>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Kirish
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
