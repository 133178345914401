import * as PG from './pages';

export const ROUTE_URLS = {
  HOME: '/',
  USERS: '/users',
  EDIT_USER: '/users/:id/edit',
  CREATE_USER: '/users/create',
  BRANCHES: '/branches',
  CREATE_BRANCH: '/branches/create',
  EDIT_BRANCH: '/branches/:id/edit',
  BRANCH_PRICES: '/branches/:id/prices',
  EDIT_BRANCH_PRICES: '/branches/:id/prices/edit',
  PRODUCTS: '/products',
  ADD_PRODUCT: '/products/add',
  CREATE_PRODUCT: '/products/create',
  EDIT_PRODUCT: '/products/:id/edit',
  REPORTS: '/reports',
  CHECKOUT: '/checkout',
  CREATE_REPORT: '/reports/create',
  BRANCH_REPORTS: '/reports/:id',
  LOGIN: '/login',
};

// Restricted routes are only accessible to admins

const routes = [
  {
    path: ROUTE_URLS.USERS,
    exact: true,
    name: 'Users',
    component: PG.Users,
    restricted: true,
  },
  {
    path: ROUTE_URLS.CREATE_USER,
    exact: true,
    name: 'Create user',
    component: PG.CreateUser,
    restricted: true,
  },
  {
    path: ROUTE_URLS.EDIT_USER,
    exact: true,
    name: 'Edit user',
    component: PG.User,
  },
  {
    path: ROUTE_URLS.PRODUCTS,
    exact: true,
    name: 'Products',
    component: PG.Products,
    restricted: true,
  },
  {
    path: ROUTE_URLS.ADD_PRODUCT,
    exact: true,
    name: 'Add product',
    component: PG.AddProduct,
    restricted: true,
  },
  {
    path: ROUTE_URLS.CREATE_PRODUCT,
    exact: true,
    name: 'Create product',
    component: PG.CreateProduct,
    restricted: true,
  },
  {
    path: ROUTE_URLS.EDIT_PRODUCT,
    exact: true,
    name: 'Edit product',
    component: PG.EditProduct,
    restricted: true,
  },
  {
    path: ROUTE_URLS.BRANCHES,
    exact: true,
    name: 'Branches',
    component: PG.Branches,
  },
  {
    path: ROUTE_URLS.CREATE_BRANCH,
    exact: true,
    name: 'Create branch',
    component: PG.CreateBranch,
    restricted: true,
  },
  {
    path: ROUTE_URLS.EDIT_BRANCH,
    exact: true,
    name: 'Edit branch',
    component: PG.EditBranch,
    restricted: true,
  },
  {
    path: ROUTE_URLS.BRANCH_PRICES,
    exact: true,
    name: 'Branch prices',
    component: PG.BranchPrices,
  },
  {
    path: ROUTE_URLS.EDIT_BRANCH_PRICES,
    exact: true,
    name: 'Edit branch prices',
    component: PG.EditBranchPrices,
  },
  {
    path: ROUTE_URLS.REPORTS,
    exact: true,
    name: 'Reports',
    component: PG.Reports,
  },
  {
    path: ROUTE_URLS.CHECKOUT,
    exact: true,
    name: 'Reports',
    component: PG.Checkout,
  },
  {
    path: ROUTE_URLS.BRANCH_REPORTS,
    exact: true,
    name: 'Branch reports',
    component: PG.Reports,
  },
  {
    path: ROUTE_URLS.CREATE_REPORT,
    exact: true,
    name: 'Create report',
    component: PG.CreateReport,
  },
  {
    path: ROUTE_URLS.HOME,
    exact: true,
    name: 'Home',
    component: PG.Home,
  },
  {
    path: '*',
    exact: false,
    name: 'Not Found',
    component: PG.NotFound,
  },
];

export default routes;
