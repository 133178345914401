import { Row, Col, Form, Input, Button } from 'antd';

import { useCreateUser } from '../../hooks';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const CreateUser = () => {
  const { isLoading, mutateAsync: createUser } = useCreateUser();
  const onFinish = values => {
    createUser(values);
  };
  return (
    <>
      <Row>
        <Col md={12} lg={8} xs={24} className={'formHolder'}>
          <h1>Xodim yaratish</h1>
          <Form name='users' layout='vertical' onFinish={onFinish}>
            <Form.Item
              label='Login'
              name='login'
              rules={[
                {
                  required: true,
                  message: `Login`,
                },
              ]}
            >
              <Input placeholder={'Login'} name='login' />
            </Form.Item>
            <Form.Item
              label='Parol'
              name='password'
              rules={[
                {
                  required: true,
                  message: `Parol`,
                },
              ]}
            >
              <Input placeholder={'Parol'} name='password' type='password' />
            </Form.Item>
            <Form.Item className={'text-center'}>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Saqlash
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
