import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Select, Form, Input, Skeleton, Card } from 'antd';

import { useBranches, useCheckout } from '../../hooks';
import { formatNumber, get7DaysBefore, getToday } from '../../utils';

import '../../styles/pageStyles.scss';
import '../../styles/helper.scss';

export const Checkout = () => {
  const { id } = useParams();
  const [selectedBranch, setSelectedBranch] = useState(id ? +id : 0);
  const [startDate, setStartDate] = useState(get7DaysBefore());
  const [endDate, setEndDate] = useState(getToday());

  const params = useMemo(() => {
    return {
      branch_id: selectedBranch,
      start_date: startDate,
      end_date: endDate,
    };
  }, [selectedBranch, startDate, endDate]);

  const { data: branches, isLoading: isLoadingBranches } = useBranches();
  const { data, isLoading } = useCheckout(params);

  const selectedBranchName = useMemo(
    () =>
      branches?.find(branch => branch.id === selectedBranch)?.name ??
      'Barcha filiallar',
    [branches, selectedBranch],
  );

  const totalCheckout= useMemo(() => {
    return data?.reduce((acc, item) => +acc + +item.total_amount, 0);
  }, [data]);

  return (
    <>
      <Form name='reportForm' initialValues={{ startDate, endDate }}>
        <Row className={'center mb-2'} gutter={16}>
          <Col xs={24} sm={12} md={8} lg={6} className={'tableTitle'}>
            <h1>{selectedBranchName} Kassa</h1>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            {isLoadingBranches ? (
              <Skeleton active />
            ) : (
              <Select
                showSearch
                placeholder={'Filial'}
                name='branch'
                className='page-selection'
                onChange={value => setSelectedBranch(value)}
                value={selectedBranch}
                rootClassName={'width-100 mb-1'}
              >
                <Select.Option value={0}>{'Barcha filiallar'}</Select.Option>
                {branches.map(branch => (
                  <Select.Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label='Start date'
              name='startDate'
              className={'mb-0 mb-1'}
            >
              <Input
                placeholder={'Sana'}
                type='date'
                name='startDate'
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item label='End Date' name='endDate' className={'mb-0 mb-1'}>
              <Input
                placeholder={'Sana'}
                type='date'
                name='endDate'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {!isLoading && (
        <Row gutter={16}>
          {data.map(item => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              style={{ marginBottom: '1rem' }}
              key={item.report_date}
            >
              <Card title={item.report_date} bordered={false}>
                {formatNumber(item.total_amount)}
              </Card>
            </Col>
          ))}
          <Col xs={24} sm={12} md={8} lg={6} style={{ marginBottom: '1rem' }}>
            <Card title={'Jami'} bordered={false}>
              {formatNumber(totalCheckout)}
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
