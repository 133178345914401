import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getReports, getReport, createReport } from '../api';
import { ROUTE_URLS } from '../routes';
import { getNotifyMessage, notify } from '../utils';

export const useReports = params => {
  const { start_date, end_date, branch_id } = params;
  return useQuery(
    ['reports', start_date, end_date, branch_id],
    async () => {
      const res = await getReports(params);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useReport = id => {
  return useQuery(
    ['report', id],
    async () => {
      const res = await getReport(id);
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateReport = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(createReport, {
    onSuccess: () => {
      notify('success', getNotifyMessage('success', 'create', 'Hisobot'));
      queryClient.invalidateQueries();
      navigate(ROUTE_URLS.REPORTS);
    },
    onError: () => {
      notify('error', getNotifyMessage('error', 'create', 'Hisobot'));
    },
  });
};
