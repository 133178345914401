import {useMemo} from "react";
import {BarChart, Bar, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer, Cell} from "recharts";

import {colorGenerator} from "../../utils";
import {ChartContainer} from "./ChartContainer";

export function BarCh({data}) {
    const colors = useMemo(() => colorGenerator(data), [data])

    return (
        <>
            {
                data?.length > 0 && <ChartContainer title={'Mahsulotlarning umumiy daromadi'}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <BarChart data={data}>
                            <XAxis dataKey="productName"/>
                            <YAxis/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Bar dataKey="totalProfit" barSize={40}>
                                {
                                    data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>
                                    ))
                                }
                            </Bar>
                            <Tooltip cursor={{fill: 'transparent'}}/>
                        </BarChart>
                    </ResponsiveContainer>
                </ChartContainer>
            }
        </>
    )
}